import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    padding: '80px 16px 16px 16px',
});

const RecentlyViewedEmpty = ({ content }) => (
    <Wrapper>
        <OverlayContent content={content.recentlyViewedEmptyContent} />
        <ThemeButton to="/all-products">Browse products</ThemeButton>
    </Wrapper>
);

RecentlyViewedEmpty.propTypes = {
    content: PropTypes.object,
};

export default RecentlyViewedEmpty;
